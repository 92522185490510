import React from 'react';
import { Modal, Button, message } from 'antd';
import Wheel from './Wheel';
import RegisterForm from '../RegisterForm';
import { AppContext } from '../appContext';

class LuckyWheel extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      finished: false,
      prize: null,
      openModal: false,
      wheelContainerWidth: 0,
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    let wheelContainerWidth = 390;
    if (this.ref && this.ref.current) {
      wheelContainerWidth = Math.floor(this.ref.current.clientWidth);
    }
    this.setState({ appWidth: window.innerWidth, wheelContainerWidth });
  };

  onFinishedGame = (prize) => {
    // console.log('prize is: ', prize);
    localStorage.setItem('prize', prize);
    setTimeout(() => this.setState({ finished: true, prize, openModal: true }), 3000);
  }

  handleSubmitInfo = (values) => {
    console.log(values);
    this.setState({ openModal: false });
    message.success("Chúng tôi đã nhận được thông tin của bạn. Phần quà sẽ được gửi tới trong thời gian sớm nhất.")
  }

  render() {
    // const { user } = this.props;
    const { wheelContainerWidth, prize, openModal } = this.state;
    return (
      <div>
        <AppContext.Consumer>
          {({user, setCurrentPage}) => (
            <div className="my-header" style={{ textAlign: 'center' }}>
              <h4 style={{ color: 'white', fontSize: '1.5em', padding: 15 }}>Chào mừng <strong>{user && user.name}</strong></h4>
              {!!prize ? (
                <div>
                  <p>Bạn đã trúng thưởng một phần quà là: <strong>{prize}</strong></p>
                  <Button onClick={() => this.setState({openModal: true })}>
                    Nhận thưởng
                  </Button>
                </div>
              ) : (
                <p style={{ color: 'white', fontSize: '1em' }}>Hãy quay để trúng thưởng!</p>
              )}
              <div id="game-menu-container">
                <a
                  href="# "
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentPage({ currentPage: 'home' });
                  }}
                  style={{ color: 'white'}}
                >
                  Trang chủ
                </a>
              </div>
              <Modal
                title="Chúc mừng bạn!"
                open={openModal}
                onOk={() => this.setState({ openModal: false })}
                onCancel={() => this.setState({ openModal: false })}
                footer={null}
              >
                <h3>Bạn đã trúng thường phần quà là <strong>{this.state.prize}</strong>!</h3>
                <p>Để nhận thưởng, bạn vui lòng nhập thông tin để shop có thể gửi quà cho bạn qua đơn vị vận chuyển.</p>
                <RegisterForm user={user} onFinish={this.handleSubmitInfo} />
              </Modal>
            </div>
          )}
        </AppContext.Consumer>
        <div ref={this.ref} id="wheel-content" className="content">
          {wheelContainerWidth > 0 && (
            <Wheel width={wheelContainerWidth} onFinished={this.onFinishedGame} />
          )}
        </div>
      </div>
    );
  }
}
export default LuckyWheel;
