import React from 'react';
import AppLogin from '../CustomGoogleLogin';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Tham gia vòng quay may mắn</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>100% trúng thưởng</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
              <p>
                Hơn 5,000 quà tặng đang chờ bạn, bao gồm kẹp tóc, quần tất... Hãy đăng nhập bằng tài khoản Gmail để tham gia ngay!
              </p>
              <AppLogin />
            </span>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: 'https://clickfreegifts.com/images/qua-tang.jpg',
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>Nội dung chương trình</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          Nhân dịp khai trương, shop sẽ dành 5,000 phần quà cho khách hàng may
          mắn:
        </p>
        <p>
          👉 Hàng ngày, mỗi người dùng sẽ có 2 lượt quay trúng thưởng.<br />👉
          Sau khi trúng thưởng, shop sẽ gửi phần qùa của bạn qua chuyển phát.
        </p>
      </span>
    ),
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Danh sách quà tặng</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>Cực kỳ tinh tế và có giá trị</p>
            <AppLogin />
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://clickfreegifts.com/images/son-duong-01.jpeg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>Son dưỡng</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>Son dưỡng môi chống khô nứt nẻ Omi Menturm Nhật Bản</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://clickfreegifts.com/images/kep-toc-1.jpeg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Kẹp tóc mẫu 01</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Kẹp tóc Hàn Quốc cực xinh, tiện dụng trong mọi trường hợp
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://clickfreegifts.com/images/kep-toc-2.jpeg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>Kẹp tóc mẫu 02</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Kẹp tóc Hàn Quốc cực xinh, tiện dụng trong mọi trường hợp
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://clickfreegifts.com/images/kep-toc-3.jpeg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>Kẹp tóc mẫu 03</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    Kẹp tóc Hàn Quốc cực xinh, tiện dụng trong mọi trường hợp
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://clickfreegifts.com/images/kep-toc-gau.jpg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>Kẹp tóc gấu</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>Kẹp tóc họa tiết gấu dễ thương phong cách Hàn Quốc&nbsp;</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://clickfreegifts.com/images/kep-toc-mai.jpg',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>Kẹp tóc mai</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>Kẹp tóc mai đính ngọc trai phong cách thời trang Hàn Quốc</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2022 <a href="https://clickfreegifts.com">ClickFreeGifts</a>&nbsp;|{' '}
        <a href="https://clickfreegifts.com/terms.html" target="_blank">
          Terms of Services
        </a>{' '}
        |{' '}
        <a href="https://clickfreegifts.com/privacy.html" target="_blank">
          Privacy
        </a>
        <br />
      </span>
    ),
  },
};
